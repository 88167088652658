var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"lg":"12"}},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){_vm.create = !_vm.create}}},[(_vm.create)?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_back_ios")]):_vm._e(),_vm._v(" "+_vm._s(_vm.create ? 'Back' : 'Add Original file')+" ")]),_c('b-button',{attrs:{"variant":"dark"},on:{"click":_vm.viewTrash}},[(!_vm.deleted)?_c('i',{staticClass:"fas fa-trash"}):_c('i',{staticClass:"fas fa-undo"}),_vm._v(" "+_vm._s(_vm.deleted ? 'Back' : 'View Trash')+" ")])],1),(_vm.create)?_c('b-col',{attrs:{"lg":"6"}},[_c('form-stock-file',{on:{"load":_vm.loadList}})],1):_vm._e(),_c('b-col',{attrs:{"lg":_vm.create? '6':'12'}},[_c('div',[_c('div',{staticClass:"p-3"},[_c('b-row',[_c('b-col',[_c('h6',[_vm._v("Stock files")])]),_c('b-col',[_c('b-form-input',{attrs:{"placeholder":"Search"},on:{"keyup":_vm.keySearch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1)],1),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"custom-table",attrs:{"bordered":"","hover":"","small":"","items":_vm.pagination.tableData,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("localDateShort")(item.created_at)))]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("localDateShort")(item.updated_at)))]}},{key:"cell(is_supplying)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.is_supplying? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.is_supplying ? 'Enabled' : 'Disabled')+" ")])]}},{key:"cell(extra)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stock_linked_file ? 'Linked' : item.client_instructions ? 'Instructions' : 'NO')+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button-group',{attrs:{"size":"sm"}},[_c('router-link',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],attrs:{"to":{name: 'project-tuned-file', params: {id: item._id}},"custom":"","content":'See project details'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-dark py-1",attrs:{"role":"link","href":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_c('i',{staticClass:"fas fa-eye"})])]}}],null,true)}),_c('b-button',{staticClass:"py-1",attrs:{"disabled":item.deleted_at,"variant":[item.is_supplying? 'success':'danger'],"title":item.is_supplying? 'Disable supply':'Enable supply'},on:{"click":function($event){return _vm.changeSupplying(item)}}},[_c('i',{staticClass:"fas",class:[item.is_supplying? 'fa-check':'fa-times']})]),(!item.deleted_at)?_c('b-button',{staticClass:"py-1",attrs:{"variant":"danger","title":"Send to trash. Soft delete"},on:{"click":function($event){return _vm.trashFile(item)}}},[_c('i',{staticClass:"fas fa-trash"})]):_c('b-button',{staticClass:"py-1",attrs:{"variant":"success","title":"Restore file"},on:{"click":function($event){return _vm.recoverFile(item)}}},[_c('i',{staticClass:"fas fa-trash-restore"})]),_c('b-button',{staticClass:"py-1",attrs:{"variant":"danger","title":"Delete file permanently"},on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('i',{staticClass:"fas fa-eraser"})])],1)]}}])})],1),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.pagination.total,"per-page":_vm.pagination.perPage,"align":"end"},on:{"change":_vm.paginate},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }