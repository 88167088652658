<template>
  <b-modal v-model="active" title="Insert flashtool protocol" @ok="save">
    <b-overlay :show="loading">
      <validation-observer ref="form">
        <validation-provider rules="required" name="Tune type" v-slot="validation">
          <b-form-group>
            <label>Tune type</label>
            <b-form-radio-group :options="['ECU','TCU']"
                                v-model="model.file_type"/>
            <span class="text-danger">{{ validation.errors[0] }}</span>
          </b-form-group>
        </validation-provider>
        <validation-provider rules="required" name="Software version" v-slot="validation">
          <b-form-group>
            <label>Software version</label>
            <b-form-input @keyup="removeSpaces" v-model="model.name" :state="getState(validation)"/>
            <span class="text-danger">{{ validation.errors[0] }}</span>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {post} from "@/services/api";

export default {
  name: "CreateFlashtoolProtocol",
  data() {
    return {
      loading: false,
      file_types: ['ECU', 'TCU'],
      active: false,
      model: {
        file_type: null,
        name: null
      }
    }
  },
  methods: {
    getState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    removeSpaces() {
      this.model.name = this.model.name.replace(/\s/g, '').toUpperCase();
    },
    open(type) {
      this.model.file_type = type;
      this.active = true;
    },
    save(e) {
      if (this.loading) return;
      this.loading = true;
      e.preventDefault();
      this.$refs.form.validate().then(success => {
        if (success) {
          post('files/flash-protocols', this.model, true).then(() => {
            this.$emit('saved', this.model)
            this.active = false;
            this.loading = false;
          })
        } else {
          this.loading = false;
        }
      });
      return false;
    }
  }
}
</script>

<style scoped>

</style>
