<template>
  <validation-observer ref="form">
    <validation-provider ref="file_add_original" rules="required" name="Stock file" v-slot="validation">
      <b-form-group>
        <label>Stock file</label>
        <b-form-file class="drop-file-input"
                     ref="file_add_original"
                     placeholder="Drop stock file here or click to select."
                     v-model="model.file"
                     :state="getState(validation)"/>
        <span class="text-danger">{{ validation.errors[0] }}</span>
      </b-form-group>
    </validation-provider>
    <h5>Specification</h5>
    <div class="p-2 mb-3">
      <validation-provider rules="required" name="Tune type" v-slot="validation">
        <b-form-group>
          <label>Tune type</label>
          <b-form-radio-group :options="['ECU','TCU']"
                              v-model="model.file_type"/>
          <span class="text-danger">{{ validation.errors[0] }}</span>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" name="Flastool" v-slot="validation">
        <b-form-group>
          <label>Flashtool</label>
          <b-form-select v-model="model.flashtool" :options="flashTools"
                         :state="getState(validation)" :disabled="!model.file_type">
            <b-form-select-option :value="null">Select</b-form-select-option>
          </b-form-select>
          <span class="text-danger">{{ validation.errors[0] }}</span>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" name="ECU" v-slot="validation">
        <b-form-group>
          <label>Flashtool Protocol</label>
          <b-form-select v-model="model.flashtool_protocol" :options="protocols"
                         :state="getState(validation)">
            <b-form-select-option :value="null">Select</b-form-select-option>
          </b-form-select>
          <span class="text-danger">{{ validation.errors[0] }}</span>
          <div class="d-flex justify-content-end pt-1">
            <b-button @click="$refs.cfp.open(model.file_type)" variant="outline-primary">Create new</b-button>
          </div>
        </b-form-group>
      </validation-provider>
    </div>
    <h5>Reference VIN</h5>
    <div>
      <b-row>
        <b-col>
          <validation-provider rules="vin_search" ref="vin" name="VIN" v-slot="validation"
                               data-vv-validate-on="change">
            <b-form-group>
              <label>VIN</label>
              <b-form-input v-model="vin" :state="getState(validation)" @keyup.enter="vinSearch"/>
              <span v-if="validation.errors[0]"
                    class="error_field">{{ $t(`vue_validator.${validation.errors[0]}`) }}</span>
            </b-form-group>
          </validation-provider>
          <b-list-group class="mb-0">
            <b-list-group-item v-for="(vin, index) in vin_searching" :key="index" :variant="vin.status"
                               class="py-2">
                      <span>
                        {{ vin.vin }}
                      </span>
              <span class="pull-right">
                        <i v-if="vin.status === 'info'" class="fas fa-spinner fa-spin"></i>
                        <i v-if="vin.status === 'success'" class="fa fa-check"></i>
                        <i v-if="vin.status === 'danger'" class="fa fa-ban"></i>
                        <i v-if="vin.status === 'warning'" class="fa fa-info-circle"
                           v-tippy="{ placement : 'top',  arrow: true }"
                           content="found on VIN-Search but not in our database"></i>
                      </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col>
          <validation-provider rules="required" name="Vehicle" v-slot="validation"
                               data-vv-validate-on="change">
            <b-form-group>
              <label>Select vehicle</label>
              <b-form-select :options="vehicles" v-model="model.vehicle_id" :state="getState(validation)">
                <b-form-select-option :value="null">Select</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </validation-provider>
          <b-list-group v-if="vehicle.brand">
            <b-list-group-item class="d-flex justify-content-between py-2">
              <span>Make</span> <span>{{ vehicle.brand }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between py-2">
              <span>Model</span> <span>{{ vehicle.model }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between py-2">
              <span>Year</span> <span>{{ vehicle.year }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between py-2">
              <span>Engine</span> <span>{{ vehicle.engine }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between py-2">
              <span>Gearbox</span> <span>{{ vehicle.dsgs.join(' / ') }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between py-2">
              <span>P/N from VIN</span> <span>{{ vehicle.hardware_version }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="priceList">
        <h6>ECU prices</h6>
        <b-table bordered :items="[priceList.ECU]" :fields="getFields(priceList.ECU)"/>
        <h6>TCU prices</h6>
        <b-table bordered :items="[priceList.TCU]" :fields="getFields(priceList.TCU)"/>
      </div>
    </div>
    <validation-provider rules="required" name="Ecu model" v-slot="validation">
      <b-form-group>
        <label>DSG family</label>
        <b-form-select :options="dsgs" v-model="model.dsg_family" :state="getState(validation)">
          <b-form-select-option :value="null">Select</b-form-select-option>
        </b-form-select>
        <span class="text-danger">{{ validation.errors[0] }}</span>
      </b-form-group>
    </validation-provider>
    <h5>Summary</h5>
    <div class="p-2">
      <validation-provider rules="required" name="Software P/N" v-slot="validation">
        <b-form-group>
          <label>Software P/N</label>
          <b-form-input @keyup="removeSpaces" v-model="model.hardware_number" :state="getState(validation)"/>
          <div class="badge badge-danger mt-1" v-if="from_file.hardware_number">
            Software P/N taken from file: <strong>{{ from_file.hardware_number }}</strong>
            <b-button @click="model.hardware_number = from_file.hardware_number" variant="dark" class="px-2 py-1 ml-2">
              Use
            </b-button>
          </div>
          <span class="text-danger">{{ validation.errors[0] }}</span>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" name="Software version" v-slot="validation">
        <b-form-group>
          <label>Software version</label>
          <b-form-input @keyup="removeSpaces" v-model="model.software_version" :state="getState(validation)"/>
          <div class="badge badge-danger mt-1" v-if="from_file.software_version">
            Software P/N taken from file: <strong>{{ from_file.software_version }}</strong>
            <b-button @click="model.software_version = from_file.software_version" variant="dark"
                      class="px-2 py-1 ml-2">Use
            </b-button>
          </div>
          <span class="text-danger">{{ validation.errors[0] }}</span>
        </b-form-group>
      </validation-provider>
      <validation-provider rules="required" name="title" v-slot="validation">
        <b-form-group>
          <label>Name / Title or description</label>
          <b-form-input placeholder="Name / Title or description" v-model="model.title"
                        :state="getState(validation)"/>
          <span class="text-danger">{{ validation.errors[0] }}</span>
        </b-form-group>
      </validation-provider>
    </div>
    <b-form-group class="text-right">
      <b-button type="submit" variant="dark" @click="save" size="lg">SAVE</b-button>
    </b-form-group>
    <create-flashtool-protocol ref="cfp" @saved="onFlashProtocolCreated"/>
  </validation-observer>
</template>

<script>
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";
import {checksum} from "@/utils";
import CreateFlashtoolProtocol from "@/views/dashboard/file-server/CreateFlashtoolProtocol.vue";

export default {
  name: "FormStockFile",
  components: {CreateFlashtoolProtocol},
  mixins: [
    show_alert_mixin
  ],
  data: () => ({
    model: {
      title: null,
      hardware_number: null,
      software_version: null,
      file_type: null,
      file: null,
      vehicle_id: null,
      dsg_family: null,
      flashtool: null,
      flashtool_protocol: null,
    },
    from_file: {
      hardware_number: null,
      software_version: null,
    },
    vin: null,
    vin_searching: [],
    vehicles: [],
    vehicle: {
      brand: null,
      model: null,
      year: null,
      engine: null,
      gearbox: null,
      ecu_code: null,
    },
    dsgs: [],
    prices: {
      'stockPrice': 'Drivability',
      'stageOnePrice': 'Stage 1',
      'stageTwoPrice': 'Stage 2',
      'stageTwoPlusPrice': 'Stage 2+',
      'stageThreePrice': 'Stage 3',
      'stageFourPrice': 'Stage 4',
    },
    flashProtocols: [],
    priceList: null,
  }),
  computed: {
    flashTools() {
      if (this.model.file_type === 'ECU')
        return ['CMD', 'TCM']
      else
        return ['TCM']
    },
    protocols() {
      return this.flashProtocols.filter(p => p.file_type === this.model.file_type)
    }
  },
  methods: {
    getState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    getFields(prices) {
      return Object.keys(prices).map(key => {
        return {
          label: this.prices[key],
          key,
        };
      });
    },
    async vinSearch(e) {
      e.preventDefault();
      const {valid} = await this.$refs.vin.validate();
      const VIN = this.vin.trim().toUpperCase();
      if (valid && !this.vin_searching.find(item => item.vin === this.vin)) {
        this.vin_searching.push({
          vin: VIN,
          status: 'info'
        })
        this.waitResponse(VIN)
        post(`site/find-by-vin`, {vin: VIN}, true)
          .then(() => {
            this.vin = null;
          }).catch(() => {
          this.sockets.unsubscribe(VIN)
          this.vin = null;
        })
      }
    },
    waitResponse(vin) {
      this.sockets.subscribe(vin, async (data) => {
        let status = data.result ? 'success' : 'danger';
        if (data.result && !data.vehicles.length) {
          status = 'warning'
        }
        this.vin_searching = this.vin_searching.map((el) => (el.vin === vin ? {
          vin,
          status,
        } : el))

        if (data.vehicles) {
          data.vehicles.forEach((vehicle) => {
            let dsgs = vehicle.dsg.split(';').map(dsg => dsg.trim());
            this.vehicles.push({
              value: vehicle.id,
              text: `${vehicle.make} - ${vehicle.model} - ${vehicle.engine} - ${vehicle.generation}`,
              brand: vehicle.make,
              model: vehicle.model,
              engine: vehicle.engine,
              generation: vehicle.generation,
              year: data.result.model_year,
              dsgs: dsgs,
              hardware_version: data.result.ecu_code,
            })
          })
        }
        this.sockets.unsubscribe(vin);
      });
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$store.commit('loaderManager', true);
          const form = new FormData();
          Object.keys(this.model).forEach(field => {
            form.append(field, this.model[field] ? this.model[field] : '');
          })
          post(`files/upload-stock-file`, form, true)
            .then(() => {
              this.clear();
              this.$refs.form.reset();
              this.$emit('load');
              this.$emit('close');
              this.$refs.form.reset();
            })
            .catch(err => {
              if (err.response && err.response.status === 409) {
                this.showAlreadyExist(err.response.data)
              } else {
                this.showAlertNotification("Something went wrong", "error")
              }
            })
            .finally(() => {this.$store.commit('loaderManager', false),  this.clear();})
        }
      })
    },
    clear() {
      this.priceList=null,
      this.model = {
        title: null,
        hardware_number: null,
        software_version: null,
        file_type: null,
        file: null,
        vehicle_id: null,
        dsg_family: null,
        flashtool: null,
      }
      this.vin_searching = []
      this.vehicles = []
      this.vehicle = {
        brand: null,
        model: null,
        year: null,
        engine: null,
        gearbox: null,
        ecu_code: null,
      }
      this.dsgs = this.dsgs.map(dsg => ({...dsg, disabled: false}))
      this.from_file = {
        hardware_number: null,
        software_version: null,
      }
      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    },
    getDsgs() {
      get('vehicles-dsg', null, true).then(({data}) => {
        this.dsgs = data.message.map(item => ({value: item.DSG, text: item.DSG}))
      })
    },
    enableOnlyTheseDsg(dsgs) {
      this.dsgs = this.dsgs.map(item => {
        if (dsgs.includes(item.value)) {
          item.disabled = false
        } else {
          item.disabled = true
        }
        return item
      })
    },
    getPrices() {
      if (this.model.vehicle_id && this.model.dsg_family) {
        get(`files/vehicle-prices/${this.model.vehicle_id}/${this.model.dsg_family}`, null, true)
          .then(({data}) => {
            this.priceList = data
          })
      }
    },
    removeSpaces(e) {
      e.target.value = e.target.value.replace(/\s/g, '');
    },
    loadFlashProtocols() {
      get(`files/flash-protocols`, null, true).then(({data}) => {
        this.flashProtocols = data.map(item => ({value: item.name, text: item.name, file_type: item.file_type}))
      })
    },
    onFlashProtocolCreated(model) {
      this.flashProtocols.push({
        value: model.name,
        text: model.name,
        file_type: model.file_type,
      })
      this.model.flashtool_protocol = model.name;
      this.model.file_type = model.file_type;
    },
    async checkAlreadyUploaded() {
      const getFileBuffer = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = (ev) => {
            resolve(
              Buffer.from(ev.currentTarget.result)
            );
          };
          reader.readAsArrayBuffer(file);
        });
      };
      if (this.model.file) {
        const checksum_res = checksum(await getFileBuffer(this.model.file))
        const formData = new FormData();
        formData.append('file', this.model.file);
        post('files/check-already-uploaded', {checksum: checksum_res}, true)
          .then(({data}) => {
            if (data.exist) {
              this.showAlreadyExist(data.exist)
            }
          })
      }
    },
    showAlreadyExist(data) {
      this.$swal({
        title: `Already a project with this original file "${data.title}"`,
        text: 'Do you want to open it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          const router = this.$router.resolve({
            name: 'project-tuned-file',
            params: {id: data._id}
          });
          window.open(router.href, '_blank');
        }else{
          this.$refs.file_add_original.type = 'text';
          this.$refs.file_add_original.type = 'file';
        }
      })
    }
  },
  watch: {
    'model.vehicle_id': function () {
      if (this.model.vehicle_id) {
        this.vehicle = this.vehicles.find(item => item.value === this.model.vehicle_id);
        this.model.dsg_family = this.vehicle.dsgs[0];
        //todo: ckeck if tune type is ECU and DSG's are multiple
        this.enableOnlyTheseDsg(this.vehicle.dsgs);
      }
    },
    'model.dsg_family': function () {
      this.getPrices();
    },
    model: {
      deep: true,
      handler: function () {
        if (this.model.hardware_number && this.model.software_version) {
          this.model.title = `${this.model.hardware_number}_${this.model.software_version}.original`
        }
        this.getPrices();
      }
    },
    'model.file_type': function () {
      this.model.flashtool = null;
    },
    'model.file': function () {
      if (this.model.file) {
        const {0: hardware_number, 1: software_version} = this.model.file.name.split('_');
        this.from_file = {
          hardware_number: hardware_number ? hardware_number.toUpperCase() : null,
          software_version: software_version ? software_version.toUpperCase() : null,
        }
      }
      this.checkAlreadyUploaded();
    },
  },
  mounted() {
    this.getDsgs();
    this.loadFlashProtocols()
  },
}
</script>

<style scoped>

</style>
