<template>
  <div class="p-3">
    <b-row>
      <b-col lg="12" class="text-right">
        <b-button @click="create = !create" variant="dark">
          <i v-if="create" class="material-icons">arrow_back_ios</i> {{ create ? 'Back' : 'Add Original file' }}
        </b-button>
        <b-button @click="viewTrash" variant="dark">
          <i class="fas fa-trash" v-if="!deleted"></i>
          <i class="fas fa-undo" v-else></i>
          {{ deleted ? 'Back' : 'View Trash' }}
        </b-button>
      </b-col>
      <b-col lg="6" v-if="create">
        <form-stock-file @load="loadList"/>
      </b-col>
      <b-col :lg="create? '6':'12'">
        <div>
          <div class="p-3">
            <b-row>
              <b-col>
                <h6>Stock files</h6>
              </b-col>
              <b-col>
                <b-form-input v-model="query" @keyup="keySearch" placeholder="Search"/>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <b-table bordered hover small class="custom-table"
                     :items="pagination.tableData" :fields="fields">
              <template #cell(created_at)="{item}">{{ item.created_at|localDateShort }}</template>
              <template #cell(updated_at)="{item}">{{ item.updated_at|localDateShort }}</template>
              <template #cell(is_supplying)="{item}">
                <b-badge :variant="item.is_supplying? 'success' : 'danger'">
                  {{ item.is_supplying ? 'Enabled' : 'Disabled' }}
                </b-badge>
              </template>
              <template #cell(extra)="{item}">
                {{ item.stock_linked_file ? 'Linked' : item.client_instructions ? 'Instructions' : 'NO' }}
              </template>
              <template #cell(actions)="{item}">
                <b-button-group size="sm">
                  <router-link :to="{name: 'project-tuned-file', params: {id: item._id}}" custom v-slot="{ navigate }"
                               v-tippy="{ placement : 'top',  arrow: true }" :content="'See project details'">
                    <a @click="navigate" @keypress.enter="navigate" role="link" href="" class="btn btn-dark py-1">
                      <i class="fas fa-eye"></i>
                    </a>
                  </router-link>
                  <b-button :disabled="item.deleted_at" @click="changeSupplying(item)"
                            :variant="[item.is_supplying? 'success':'danger']" :title="item.is_supplying? 'Disable supply':'Enable supply'"
                            class="py-1">
                    <i class="fas" :class="[item.is_supplying? 'fa-check':'fa-times']"></i>
                  </b-button>
                  <b-button v-if="!item.deleted_at" @click="trashFile(item)" variant="danger" class="py-1" title="Send to trash. Soft delete">
                    <i class="fas fa-trash"></i>
                  </b-button>
                  <b-button v-else @click="recoverFile(item)" variant="success" class="py-1" title="Restore file">
                    <i class="fas fa-trash-restore"></i>
                  </b-button>
                  <b-button @click="deleteFile(item)" variant="danger" class="py-1" title="Delete file permanently">
                    <i class="fas fa-eraser"></i>
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <b-pagination v-model="pagination.currentPage" :total-rows="pagination.total" :per-page="pagination.perPage"
                        align="end" class="my-0" @change="paginate"/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {Pagination} from "@/utils/pagination";
import {deleted, get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";
import FormStockFile from "@/views/dashboard/file-server/FormStockFile";

export default {
  name: "StockFiles",
  components: {FormStockFile},
  mixins: [show_alert_mixin],
  data: () => ({
    pagination: new Pagination(),
    query: '',
    deleted: false,
    fields: [
      {key: '_id', label: 'Project ID', sortable: true},
      {key: 'file_type', label: 'TUNE TYPE', sortable: true},
      {key: 'flashtool', label: 'TOOL', sortable: true},
      {key: 'flashtool_protocol', label: 'Flash tool protocol', sortable: true},
      {key: 'dsg_family', label: 'DSG Family', sortable: true},
      {key: 'hardware_number', label: 'SOFTWARE P/N', sortable: true},
      {key: 'software_version', label: 'SOFTWARE VERSION', sortable: true},
      {key: 'tuned_count', label: 'AVAILABLE', sortable: true},
      {key: 'created_at', label: 'CREATED AT', sortable: true},
      {key: 'updated_at', label: 'LAST REVISION', sortable: true},
      {key: 'is_supplying', label: 'Auto supply', sortable: true},
      {key: 'extra', label: 'Is linked or Client Instr'},
      'actions'
    ],
    timer: null,
    create: false,
  }),
  methods: {
    paginate(page) {
      this.pagination.currentPage = page;
      this.loadList()
    },
    loadList() {
      this.$store.commit('orderSoftwareFile/showDsgConversionAndFlex', false);
      get(`files/stock?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&q=${this.query ? this.query : ''}&deleted=${this.deleted}`, null, true)
        .then(({data}) => {
          this.pagination.total = data.count;
          this.pagination.setTableData(data.data)
        })
    },
    keySearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.pagination.currentPage = 1;
        this.loadList()
      }, 800);
    },
    changeSupplying(item) {
      post(`files/change-stock-supplying/${item._id}`, {id: item._id}, true)
        .then(() => {
          this.loadList()
        })
    },
    deleteFile(item) {
      this.$swal({
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure? This action cannot be undone',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.$store.commit('loaderManager', true);
          deleted(`files/stock/${item._id}`, true)
            .then(() => {
              this.loadList()
            })
            .finally(() => this.$store.commit('loaderManager', false))
        }
      })
    },
    trashFile(item) {
      this.$swal({
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure you want to move this file to Trash?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.$store.commit('loaderManager', true);
          post(`files/stock/${item._id}`, null, true)
            .then(() => {
              this.loadList()
            })
            .finally(() => this.$store.commit('loaderManager', false))
        }
      })
      this.loadList()
    },
    recoverFile(item) {
      this.$swal({
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure you want to put it back?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.$store.commit('loaderManager', true);
          post(`files/stock-recover/${item._id}`, null, true)
            .then(() => {
              this.loadList()
            }).catch(() => {
            this.$swal({
              icon: 'error',
              title: 'Error',
              text: 'this file cannot be recovered, there is currently an active file with the same data',
              confirmButtonColor: "#d14343",
            })
          })
            .finally(() => this.$store.commit('loaderManager', false))
        }
      })
    },
    viewTrash() {
      this.pagination.currentPage = 1;
      this.deleted = !this.deleted;
      this.loadList()
    },
  },
  mounted() {
    this.loadList()
  },
}
</script>

<style scoped lang="scss">

</style>
